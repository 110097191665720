@tailwind base;
@tailwind components;
@tailwind utilities;

/* Path: src/components/Heading/Heading.css */
/*  Retrive the default margin of the p element */

p {
  margin-block-start: 1em;
  margin-block-end: 1em;
}

hr {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
}
